import React, { useContext, useEffect } from "react";
import { Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import { useAuthorization } from "../components/Authorization/useAuthorization";
import { WithAuth } from "../components/Authorization/WithAuth";
import Card from "../components/Cards/Card";
import { CustomSelect } from "../components/CustomControls/CustomSelect";
import { LaboratoriesContainer } from "../containers/Protocols/Admin/Laboratories";
import { StudyTasksContainer } from "../containers/Protocols/Admin/StudyTasks";
import { RolesContainer } from "../containers/Security/Authorization/Roles";
import { StudyRolesContainer } from "../containers/Security/Authorization/StudyRoles";
import AdminContext from "../containers/Settings/AdminContext/AdminContext";
import { AdminProvider } from "../containers/Settings/AdminContext/AdminProvider";
import { ResultsTemplatesContainer } from "../containers/Settings/ResultsTemplates";
import { SettingsContainer } from "../containers/Settings/SettingsContainer";
import { SyncContainer } from "../containers/Settings/SyncContainer";
import { useTenants } from "../containers/Tenants/Hooks";
import { TenantsContainer } from "../containers/Tenants/Tenants";
import { UsersContainer } from "../containers/Users/Users";
import { useTranslation } from "react-i18next";
import AdminNotificationsContainer from "../containers/Settings/AdminNotifications/AdminNotificationsContainer";

const TenantSelection = ({ isAuthorized }) => {
    const { selectedTenant, setSelectedTenant } = useContext(AdminContext);
    const [tenants, isTenantsLoading, getAll] = useTenants();

    useEffect(() => {
        try {
            if (isAuthorized(["Tenant.View"]))
                getAll();
        }
        catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const handleSelectedTenant = (_name, value) => {
        setSelectedTenant(value)
    }

    return (
        <Card
            content={
                <Row>
                    <Col md={2}>
                        <CustomSelect
                            name="TenantId"
                            placeholder="Cliente"
                            onChange={handleSelectedTenant}
                            isMulti={false}
                            isClearable={true}
                            loading={isTenantsLoading}
                            options={tenants}
                            getOptionLabel={(x) => x.tenantName}
                            getOptionValue={(x) => x.tenantId}
                        />
                    </Col>
                    <Col md={4}>
                        {selectedTenant && <div>TenantId: {selectedTenant.tenantId}</div>}
                    </Col>
                </Row>
            }
        />
    );
}

const Settings = () => {
    const { t } = useTranslation();
    const [, , isAuthorized] = useAuthorization();

    return (
        <AdminProvider>
            <WithAuth requiredPermission={["Tenant.View"]}>
                <TenantSelection isAuthorized={isAuthorized} />
            </WithAuth>
            <Tab.Container id="medical-record-home" defaultActiveKey="users">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="tabs">
                            {
                                isAuthorized(["Tenant.View"]) && <NavItem eventKey="tenants">{t("settings.settings.tenants")}</NavItem>
                            }
                            {
                                isAuthorized(["Users.View"]) && <NavItem eventKey="users">{t("settings.settings.users")}</NavItem>
                            }
                            {
                                isAuthorized(["Security.View"]) && <NavItem eventKey="roles">{t("settings.settings.roles")}</NavItem>
                            }
                            {
                                isAuthorized(["Protocols.View"]) && <NavItem eventKey="protocol-rol">{t("settings.settings.protocolRol")}</NavItem>
                            }
                            {
                                isAuthorized(["Protocols.View"]) && <NavItem eventKey="protocol-tasks">{t("settings.settings.protocolTasks")}</NavItem>
                            }
                            {
                                isAuthorized(["Protocols.View"]) && <NavItem eventKey="laboratories">{t("settings.settings.laboratories")}</NavItem>
                            }
                            {
                                isAuthorized(["Tenant.Create"]) && <NavItem eventKey="sync">{t("settings.settings.sync")}</NavItem>
                            }
                            {
                                !isAuthorized(["Tenant.View"]) && isAuthorized(["Security.View"]) && <NavItem eventKey="resultsTemplates">{t("settings.settings.resultsTemplates")}</NavItem>
                            }
                            {
                                !isAuthorized(["Tenant.View"]) && isAuthorized(["Settings.Edit"]) && <NavItem eventKey="settings">{t("settings.settings.settings")}</NavItem>
                            }
                            {
                                isAuthorized(["Tenant.Create"]) && <NavItem eventKey="adminNotifications">{t("settings.adminNotifications.systemNotificationsTitle")}</NavItem>
                            }
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content animation mountOnEnter={true}>
                            <Tab.Pane eventKey="tenants">
                                <TenantsContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="users">
                                <UsersContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="roles">
                                <RolesContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="protocol-rol">
                                <StudyRolesContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="protocol-tasks">
                                <StudyTasksContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="laboratories">
                                <LaboratoriesContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sync">
                                <SyncContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="resultsTemplates">
                                <ResultsTemplatesContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="settings">
                                <SettingsContainer />
                            </Tab.Pane>
                            <Tab.Pane eventKey="adminNotifications">
                                <AdminNotificationsContainer />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </AdminProvider>
    );
}

export default Settings;
