import { ErrorMessage, FieldArray } from 'formik'
import i18n from 'i18next'
import React from 'react'
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup, Panel, PanelGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { conceptType } from '../../variables/Enums'
import Card from '../Cards/Card'
import { AdherenceControl } from '../CustomControls/Adherence'
import { AuditTooltip } from '../CustomControls/AuditTooltip'
import { CustomCheckbox } from '../CustomControls/CustomCheckbox'
import { NullableDatePicker } from '../CustomControls/CustomDate'
import { CustomDateTime, CustomTime } from "../CustomControls/CustomDateTime"
import { CustomFormControl } from '../CustomControls/CustomFormControl'
import { CustomRadio } from '../CustomControls/CustomRadio'
import { AdverseEventsAsync, CustomSelect, CustomSelectAsync } from "../CustomControls/CustomSelect"
import { FilesForm } from '../CustomControls/Files/Files'
import { SnomedPicker } from '../CustomControls/SnomedPicker'
import { YesNo, YesNoNotApply } from '../CustomControls/YesNoControls'
import { Remove } from '../Icons/Icons'
import { AdverseEventsOpenItems } from '../MedicalRecords/AdverseEvents'
import { DrugsOpenItems } from '../MedicalRecords/Drugs'
import { ProceduresOpenItems } from '../MedicalRecords/Procedures'
import { toLocaleDateFormatter } from '../Utils/Formatter'
import { FormErrorMessage } from '../Validations/FormValidationErrors'


const getDynamicField = (field, indexComp, indexChild, index, props, t) => {
    const {
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isDisabled,
        isUploadEnabled
    } = props;

    let name = `fields[${index}]`;
    if (indexChild !== null && indexChild !== undefined) {
        name = `fields[${index}].components[${indexComp}].fields[${indexChild}]`;
    }
    let auditName = `${name}.value`;

    let dynamicField = null;
    switch (field._t) {
        case 'DividerField':
            return (
                <Row key={`formHorizontal${field.name}${index}`}>
                    <Col componentClass={ControlLabel} sm={12}>
                        <hr />
                    </Col>
                </Row>
            );
        case 'TextField':
            return (
                <Row key={`formHorizontal${field.name}${index}`}>
                    <Col componentClass={ControlLabel} sm={12}>
                        {field.label}
                    </Col>
                </Row>
            );
        case 'SingleLineField':
            dynamicField = (<CustomFormControl disabled={isDisabled} type="text" placeholder={field.placeholder} name={`${name}.value`} value={field.value ?? ''} onChange={setFieldValue} onBlur={setFieldTouched} />);
            break;
        case 'MultiLineField':
            dynamicField = (<CustomFormControl disabled={isDisabled} type="textarea" rows={5} placeholder={field.placeholder} name={`${name}.value`} value={field.value ?? ''} onChange={setFieldValue} onBlur={setFieldTouched} />);
            break;
        case 'IntegerField':
        case 'DecimalField':
            if (field.text && field.text !== "") {
                dynamicField = (
                    <InputGroup>
                        {field.textPosition == 0 ? <InputGroup.Addon>{field.text}</InputGroup.Addon> : ""}
                        <CustomFormControl disabled={isDisabled} type="number" placeholder={field.placeholder} name={`${name}.value`} value={field.value ?? ''} onChange={setFieldValue} onBlur={setFieldTouched} />
                        {field.textPosition == 1 ? <InputGroup.Addon>{field.text}</InputGroup.Addon> : ""}
                    </InputGroup>);
            }
            else {
                dynamicField = (<CustomFormControl disabled={isDisabled} type="number" placeholder={field.placeholder} name={`${name}.value`} value={field.value ?? ''} onChange={setFieldValue} onBlur={setFieldTouched} />);
            }
            break;
        case 'DateField':
            dynamicField = (<NullableDatePicker
                disabled={isDisabled}
                value={field.value}
                name={`${name}.value`}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                futureDates={true}
            />);
            break;
        case 'TimeField':
            dynamicField = (<CustomTime
                disabled={isDisabled}
                value={field.value ?? ''}
                name={`${name}.value`}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                closeOnSelect={true}
            />);
            break;
        case 'DateTimeField':
            dynamicField = (<CustomDateTime
                disabled={isDisabled}
                value={field.value ?? ''}
                name={`${name}.value`}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                closeOnSelect={true}
            />);
            break;
        case 'PayableField':
        case 'BooleanField':
            dynamicField = (<YesNo
                disabled={isDisabled}
                value={field.value ?? ''}
                onChange={setFieldValue}
                name={`${name}.value`}
                isClearable={!field.required} />);
            break;
        case 'YesNoField':
            dynamicField = (<YesNoNotApply
                disabled={isDisabled}
                value={field.value ?? ''}
                onChange={setFieldValue}
                name={`${name}.value`}
                isClearable={!field.required} />);
            break;
        case 'SnomedField':
            let type = null;
            switch (field.syncTo) {
                case "AdverseEvent": type = conceptType.events; break;
                case "Drug": type = conceptType.drugs; break;
                case "Procedure": type = conceptType.procedures; break;
                default:
                    type = conceptType.events; break;
            }
            auditName += ".description";
            dynamicField = (<SnomedPicker
                conceptType={type}
                disabled={isDisabled}
                selected={field.value ? [field.value] : []}
                id={`${name}.value`}
                name={`${name}.value`}
                placeholder={field.placeholder}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                conceptId={field.snomedExpression} />);
            break;
        case 'ProcedureSnomedField':
            auditName += ".description";
            dynamicField = (<SnomedPicker
                conceptType={conceptType.procedures}
                disabled={isDisabled}
                selected={field.value ? [field.value] : []}
                id={`${name}.value`}
                name={`${name}.value`}
                placeholder={field.placeholder}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                conceptId={field.snomedExpression} />);
            break;
        case 'SelectField':
            auditName += ".title";
            let options = field.options.split("\n").map((option, idx) => { return { id: idx, title: option } });
            dynamicField = (<CustomSelect
                isDisabled={isDisabled}
                value={field.value ?? ''}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                name={`${name}.value`}
                placeholder={field.placeholder}
                options={options} />);
            break;
        case 'SelectAsyncField':
            auditName += ".title";
            dynamicField = (<CustomSelectAsync
                isDisabled={isDisabled}
                value={field.value ?? ''}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                name={`${name}.value`}
                placeholder={field.placeholder}
                masterData={field.masterData?.code} />);
            break;
        case 'AdverseEventsAsyncField':
            dynamicField = (<AdverseEventsAsync
                isDisabled={isDisabled || false}
                value={field.value ?? ''}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                name={`${name}.value`}
                placeholder={field.placeholder}
                protocolId={props.protocolId}
                visitId={props.visitId}
                medicalRecordNumber={props.patientId}
                patientNumber={props.patientNumber} />);
            break;
        case 'CheckboxField':
            dynamicField = (<CustomCheckbox
                disabled={isDisabled}
                name={`${name}.value`}
                label={field.placeholder}
                isChecked={field.value ?? ''}
                onChange={handleChange} />);
            break;
        case 'MultiCheckBoxField':
            let multicheckOptions = field.options?.split("\n").map((option, idx) => { return { id: `${idx}_${field.order}`, idx, title: option } });
            dynamicField = multicheckOptions.map(option => {
                return (<CustomCheckbox
                    disabled={isDisabled}
                    key={`${name}.value.${option.id}`}
                    id={`${name}.value.${option.idx}`}
                    name={`${name}.value`}
                    label={option.title}
                    checked={field.value && field.value[option.idx]}
                    onChange={(name, checked, idx) => {
                        if (!idx) return;
                        let checkboxArray = !field.value ? multicheckOptions.map(() => false) : field.value;
                        checkboxArray[idx.split('.')?.pop()] = checked;
                        setFieldValue(name, checkboxArray);
                    }}
                />);
            });
            break;
        case 'RadioField':
            let radioOptions = field.options.split("\n").map((option, idx) => { return { id: `${idx}_${field.order}`, idx, title: option } });
            dynamicField = radioOptions.map(option => {
                return (<CustomRadio
                    disabled={isDisabled}
                    key={`${name}.value.${option.id}`}
                    number={`${name}.value.${option.idx}`}
                    name={`${name}.value`}
                    label={option.title}
                    //number={option.id}
                    option={option.title}
                    checked={option.title === field.value}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                />);
            });
            break;
        case 'FileUploadField':
            auditName += ".fileName";
            dynamicField = (<>
                <FilesForm
                    disabled={isDisabled && !isUploadEnabled}
                    values={field.value}
                    name={`${name}.value`}
                    placeholder={field.placeholder}
                    onChange={setFieldValue}
                    onDownload={props.onDownload}
                    accept='image/*,.pdf'
                    multiple={false}
                />
                {
                    field.value && field.value.uploadedBy && field.value.uploadedBy !== "00000000-0000-0000-0000-000000000000" && props.currentUser !== field.value.uploadedBy &&
                    <CustomCheckbox
                        disabled={isDisabled}
                        name={`${name}.value.validatedBy`}
                        label={i18n.t("protocols.visit.formTemplate.label_fileUploadField")}
                        isChecked={field.value.validatedBy ?? ''}
                        onChange={(controlName, value) => { if (value) setFieldValue(controlName, props.currentUser); else setFieldValue(controlName, null) }} />
                }
            </>);
            break;
        case 'AdherenceField':
            dynamicField = (
                <AdherenceControl
                    isDisabled={isDisabled}
                    placeholder={field.placeholder}
                    name={`${name}.value`}
                    value={field.value}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                />);
            break;
        case 'BMIField':
            let montotoFields = props.values.fields;
            if (indexChild !== null && indexChild !== undefined) {
                montotoFields = props.values.fields[index].components[indexComp].fields;
            }
            const height = montotoFields.find(x => x.syncTo === "Height");
            const weight = montotoFields.find(x => x.syncTo === "Weight");

            if (!height || !weight) {
                dynamicField = i18n.t("protocols.visit.formTemplate.label_bmiField"); break;
            }
            const heightFloat = parseFloat(height.value);
            const weightFloat = parseFloat(weight.value);
            if (!isNaN(heightFloat) && !isNaN(weightFloat) && heightFloat > 0) {
                const bmi = weightFloat / (heightFloat / 100 * heightFloat / 100);
                dynamicField = <Row key={`formHorizontal${field.name}${index}`}>
                    <Col componentClass={ControlLabel} sm={12}>
                        {Math.round(bmi * 10) / 10}
                    </Col>
                </Row>
            }
            break;
        case 'AdverseEventsOpenItemsField':
            dynamicField = (
                <AdverseEventsOpenItems
                    title={field.label}
                    isDisabled={isDisabled || false}
                    placeholder={field.placeholder}
                    name={`${name}.value`}
                    value={field.value}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    medicalRecordNumber={props.patientId}
                />);
            break;
        case 'DrugsOpenItemsField':
            dynamicField = (
                <DrugsOpenItems
                    title={field.label}
                    isDisabled={isDisabled}
                    placeholder={field.placeholder}
                    name={`${name}.value`}
                    value={field.value}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    medicalRecordNumber={props.patientId}
                />);
            break;
        case 'ProceduresOpenItemsField':
            dynamicField = (
                <ProceduresOpenItems
                    title={field.label}
                    isDisabled={isDisabled}
                    placeholder={field.placeholder}
                    name={`${name}.value`}
                    value={field.value}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    medicalRecordNumber={props.patientId}
                />);
            break;
        default:
            dynamicField = (<FormControl type="text" disabled placeholder="TypeField not implemented yet." name={`${name}.value`} value={field.value} onChange={handleChange} onBlur={handleBlur} />);
            break;
    }

    if (props.getLastChange && field._t !== "AdverseEventsOpenItemsField" && field._t !== "DrugsOpenItemsField" && field._t !== "ProceduresOpenItemsField") {
        const audit = props.getLastChange(auditName);
        if (audit !== null) {
            return (
                <Row>
                    <Col md={10}>
                        {dynamicField}
                    </Col>
                    <Col md={2}>
                        <AuditTooltip id="visitText" text={audit} />
                    </Col>
                </Row>
            );
        }
    }

    return dynamicField;
}

const getArrayFieldComponent = (field, index, props, t) => {
    const { isDisabled } = props;

    return (
        <PanelGroup accordion id={`accordion-items-${index}`} key={`array-field-component-${index}`}>
            <div className="clearfix"></div>
            <FieldArray
                key={`arrayFieldContainer${index}`}
                name={`fields[${index}].components`}
                render={arrayHelper => (
                    <div>
                        {field.components && field.components.length > 0 ? (
                            field.components.map((component, indexComp) => {
                                const sortedFields = component.fields.sort((function (a, b) { return a.order - b.order; }));

                                if (!sortedFields || sortedFields.length === 0)
                                    return '';

                                return (
                                    <Panel key={`accordion-audit-item-${indexComp}`} eventKey={`accordion-audit-item-${indexComp}`}>
                                        <Panel.Heading>
                                            <Panel.Title toggle>{sortedFields[0].value?.description ?? sortedFields[0].label}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <Card
                                                key={`arrayFieldComponent${indexComp}`}
                                                content={
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn-remove btn-table-actions"
                                                            onClick={() => arrayHelper.remove(indexComp)}
                                                            disabled={isDisabled}
                                                        >
                                                            <span aria-hidden="true"><Remove /></span>
                                                        </button>
                                                        {
                                                            sortedFields.map(function (fieldRec, indexRec) {
                                                                return (
                                                                    <div key={`arrayFieldcontent${index}.${indexRec}`}>
                                                                        <FormGroup bsClass="row" controlId={`formHorizontal${fieldRec.name}${indexRec}`} key={`formHorizontal${fieldRec.name}${indexRec}`}>
                                                                            <Col componentClass={ControlLabel} sm={4}>
                                                                                {fieldRec.label} {fieldRec.required && <span style={{ color: 'red' }}>*</span>}
                                                                            </Col>
                                                                            <Col sm={8}>
                                                                                {getDynamicField(fieldRec, indexComp, indexRec, index, props)}
                                                                                <ErrorMessage name={`fields[${index}].components[${indexComp}].fields[${indexRec}].value`} component={FormErrorMessage} />
                                                                                <HelpBlock>{fieldRec.note}</HelpBlock>
                                                                            </Col>
                                                                        </FormGroup>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            />
                                        </Panel.Body>
                                    </Panel>
                                );
                            })
                        ) : ""}
                        <button type="button" disabled={isDisabled} className="btn btn-default" style={{ marginTop: '20px' }} onClick={() => { arrayHelper.push({ fields: field.template }) }}>
                            {t("protocols.visit.formTemplate.addField")}
                        </button>
                    </div>
                )}
            />
        </PanelGroup>
    );
}

const getTableArrayFieldComponent = (field, index, props, t) => {
    const { isDisabled } = props;

    if (!field.components && field.components.length === 0)
        return null;

    return (
        <FieldArray
            key={`arrayFieldContainer${index}`}
            name={`fields[${index}].components`}
            render={arrayHelper => (
                <div>
                    {
                        field && field.components[0] && field.components[0].fields &&
                        <table className="table table-responsive">
                            <thead>
                                <tr key={`arrayFieldHeader-${index}`}>
                                    {
                                        field.components[0].fields?.sort((function (a, b) { return a.order - b.order; }))
                                            .map(function (fieldRec, indexRec) {
                                                return (
                                                    <th key={`arrayFieldHeader-${index}-${indexRec}`}>
                                                        {fieldRec.label}
                                                    </th>
                                                )
                                            })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    field.components.map((component, indexComp) => {
                                        const sortedFields = component.fields?.sort((function (a, b) { return a.order - b.order; }));

                                        if (!sortedFields || sortedFields.length === 0)
                                            return '';

                                        return (<tr key={`arrayFieldcontent${index}-${indexComp}`}>
                                            {
                                                sortedFields.map(function (fieldRec, indexRec) {
                                                    return (
                                                        <th key={`arrayFieldcontent${index}-${indexComp}-${indexRec}`}>
                                                            {getDynamicField(fieldRec, indexComp, indexRec, index, props)}
                                                            <ErrorMessage name={`fields[${index}].components[${indexComp}].fields[${indexRec}].value`} component={FormErrorMessage} />
                                                            <HelpBlock>{fieldRec.note}</HelpBlock>
                                                        </th>
                                                    )
                                                })
                                            }
                                            <button
                                                type="button"
                                                disabled={isDisabled}
                                                className="btn-remove btn-table-actions"
                                                onClick={() => arrayHelper.remove(indexComp)} >
                                                <span aria-hidden="true"><Remove /></span>
                                            </button>
                                        </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    }

                    <button type="button" disabled={isDisabled} className="btn btn-default" style={{ marginTop: '20px' }} onClick={() => { arrayHelper.push({ fields: field.template }) }}>
                        {t("protocols.visit.formTemplate.addField")}
                    </button>
                </div>
            )}
        />
    );
}

export const DynamicForm = props => {
    const { values } = props;
    const { t } = useTranslation();

    //values es el Form con sus propiedades. 
    if (!values || !values.fields)
        return null;

    const fields = values.fields.sort((function (a, b) { return a.order - b.order; }))
        .map(function (field, index) {

            field.value = field.value ?? '';

            switch (field._t) {
                case 'ArrayField':
                    if (values.type === "LaboratoryResults")
                        return getTableArrayFieldComponent(field, index, props, t);

                    return getArrayFieldComponent(field, index, props, t);
                case 'DividerField':
                case 'TextField':
                    return getDynamicField(field, null, null, index, props, t);
                default:
                    return (
                        <Row key={`formHorizontal${field.name}${index}`}>
                            <Col componentClass={ControlLabel} sm={12}>
                                {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
                            </Col>
                            <Col sm={12}>
                                {getDynamicField(field, null, null, index, props, t)}
                                <ErrorMessage name={`fields[${index}].value`} component={FormErrorMessage} />
                                <HelpBlock>{field.note}</HelpBlock>
                            </Col>
                        </Row>
                    );
            }
        });

    return (
        <Card
            className="card-visit-tracking"
            title={values.title}
            subtitle={values.status ?
                <>
                    <div>{t("protocols.visitTracking.firstSave")}: {toLocaleDateFormatter(values.created, "DD/MMM/YYYY HH:mm")}</div>
                    <div>{t("protocols.visitTracking.lastSave")}: {toLocaleDateFormatter(values.modified, "DD/MMM/YYYY HH:mm")}</div>
                </> 
                : null}
            content={
                <>
                    {fields}
                    <div className="clearfix"></div>
                </>
            }
        />
    );
};