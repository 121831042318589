import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { CustomSwitch } from "../CustomControls/CustomSwitch";
import { useTranslation } from "react-i18next";

export const StudyPermissions = (props) => {
    const { claims, onChange, disabled, name } = props;
    const [selectedClaims, setSelectedClaims] = useState([]);
    const { t } = useTranslation();

    const modules = [
        { id: 1, code: "Brief", title: t("protocolStudyRoles.modules.brief") },
        { id: 2, code: "Practitioners", title: t("protocolStudyRoles.modules.practitioners") },
        { id: 3, code: "Patients", title: t("protocolStudyRoles.modules.patients") },
        { id: 4, code: "Visits", title: t("protocolStudyRoles.modules.visits") },
        { id: 5, code: "VisitTracking", title: t("protocolStudyRoles.modules.visitTracking") },
        { id: 6, code: "InternalNotes", title: t("protocolStudyRoles.modules.internalNotes") },
        { id: 7, code: "ExternalNotes", title: t("protocolStudyRoles.modules.externalNotes") },
    ];

    useEffect(() => {
        setSelectedClaims(claims);
    }, [])

    const handleChange = (permission, value) => {
        const splitted = permission.split('.');
        const module = parseInt(splitted[0]);
        const action = parseInt(splitted[1]);

        let permissionsCopy = null;

        if (value) {
            // Agregado
            permissionsCopy = [...JSON.parse(JSON.stringify(selectedClaims)), { module, action }];
            setSelectedClaims(permissionsCopy)
        }
        else {
            // Eliminado
            const indexToRemove = selectedClaims.findIndex(x => x.module === module && x.action === action);
            permissionsCopy = JSON.parse(JSON.stringify(selectedClaims)).filter((_, i) => i !== indexToRemove);
            setSelectedClaims(permissionsCopy)
        }

        if (onChange)
            onChange(name, permissionsCopy);
    };

    const yes = (name) => (<CustomSwitch value={true} name={name} disabled={disabled} onChange={handleChange} />);
    const no = (name) => (<CustomSwitch value={false} name={name} disabled={disabled} onChange={handleChange} />);

    return (
        <table className="table table-hover table-spaced-small">
            <thead>
                <tr>
                    <th>{t('security.permissions.module')}</th>
                    <th>{t('security.permissions.view')}</th>
                    <th>{t('security.permissions.list')}</th>
                    <th>{t('security.permissions.create')}</th>
                    <th>{t('security.permissions.edit')}</th>
                    <th>{t('security.permissions.delete')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {modules.map((x, idx) => {
                    return (<tr key={`studyRolesPermissions-${idx}`}>
                        <td>{x.title}</td>
                        <td>{selectedClaims.filter(c => c.module === x.id && c.action === 5).length > 0 ? yes(`${x.id}.5`) : no(`${x.id}.5`)}</td>
                        <td>{selectedClaims.filter(c => c.module === x.id && c.action === 4).length > 0 ? yes(`${x.id}.4`) : no(`${x.id}.4`)}</td>
                        <td>{selectedClaims.filter(c => c.module === x.id && c.action === 1).length > 0 ? yes(`${x.id}.1`) : no(`${x.id}.1`)}</td>
                        <td>{selectedClaims.filter(c => c.module === x.id && c.action === 2).length > 0 ? yes(`${x.id}.2`) : no(`${x.id}.2`)}</td>
                        <td>{selectedClaims.filter(c => c.module === x.id && c.action === 3).length > 0 ? yes(`${x.id}.3`) : no(`${x.id}.3`)}</td>
                        {
                            (idx === 3 || idx === 4) &&
                            <td>{t('security.permissions.publish')}{selectedClaims.filter(c => c.module === x.id && c.action === 6).length > 0 ? yes(`${x.id}.6`) : no(`${x.id}.6`)}</td>
                        }
                        {
                            (idx === 0) &&
                            <td>{t('security.permissions.close')}{selectedClaims.filter(c => c.module === x.id && c.action === 7).length > 0 ? yes(`${x.id}.7`) : no(`${x.id}.7`)}</td>
                        }
                        {
                            (idx === 4) &&
                            <td>{t('security.permissions.upload')}{selectedClaims.filter(c => c.module === x.id && c.action === 8).length > 0 ? yes(`${x.id}.8`) : no(`${x.id}.8`)}</td>
                        }
                        {
                            (idx === 4) &&
                            <td>{t('security.permissions.exportPatientVisit')}{selectedClaims.filter(c => c.module === x.id && c.action === 9).length > 0 ? yes(`${x.id}.9`) : no(`${x.id}.9`)}</td>
                        }
                    </tr>
                    )
                })}
            </tbody>
        </table>
    );
}

StudyPermissions.propTypes = {
    onChange: PropTypes.func,
    claims: PropTypes.array.isRequired
};

StudyPermissions.defaultProps = {
    disabled: false,
    onText: "Si",
    offText: "No",
    bsSize: "small"
};