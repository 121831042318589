import React from 'react'
import PropTypes from "prop-types";
import { withFormik } from 'formik'
import { withTranslation } from 'react-i18next'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import { CustomSwitch } from '../CustomControls/CustomSwitch'
import { CustomRadio } from '../CustomControls/CustomRadio'
import { CustomSelect } from '../CustomControls/CustomSelect'
import { SnomedPicker } from '../CustomControls/SnomedPicker';
import { snomedExpressions } from '../../variables/Enums';

const DynamicFormOptions = props => {
    const {
        handleSubmit,
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        onClose,
        t
    } = props;

    const allFieldsProps = (<div key="fields_commons">
        <FormGroup>
            <ControlLabel>{t("protocols.visit.formTemplate.optionsLabel")}</ControlLabel>
            <FormControl type="text" name="label" value={values.label} onChange={handleChange} />
            <FormControl.Feedback />
        </FormGroup>
        <FormGroup>
            <ControlLabel>{t("protocols.visit.formTemplate.optionsPlaceholder")}</ControlLabel>
            <FormControl type="text" name="placeholder" value={values.placeholder} onChange={handleChange} />
            <FormControl.Feedback />
        </FormGroup>
        <FormGroup>
            <ControlLabel>{t("protocols.visit.formTemplate.optionsRequired")}</ControlLabel>
            <CustomSwitch name="required" value={values.required} onChange={setFieldValue} />
            <FormControl.Feedback />
        </FormGroup>
        <FormGroup>
            <ControlLabel>{t("protocols.visit.formTemplate.optionsDescription")}</ControlLabel>
            <FormControl type="text" name='note' value={values.note} onChange={handleChange} />
            <FormControl.Feedback />
        </FormGroup>
    </div>);

    let dynamicOptions = null;
    switch (values._t) {
        case 'TextField':
        case 'DrugsOpenItemsField':
        case 'ProceduresOpenItemsField':
        case 'AdverseEventsOpenItemsField':
            dynamicOptions = (<FormGroup>
                <ControlLabel>{t("protocols.visit.formTemplate.optionsText")}</ControlLabel>
                <FormControl type="text" name='label' value={values.label} onChange={handleChange} />
                <FormControl.Feedback />
            </FormGroup>);
            break;
        case 'SingleLineField':
        case 'MultiLineField':
        case 'YesNoField':
        case 'BooleanField':
        case 'PayableField':
        case 'DateField':
        case 'TimeField':
        case 'DateTimeField':
        case 'CheckboxField':
        case 'AdverseEventsAsyncField':
        case 'AdherenceField':
            dynamicOptions = allFieldsProps;
            break;
        case 'FileUploadField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsSyncTo")}</ControlLabel>
                    <CustomSwitch name="syncTo" value={values.syncTo} onChange={setFieldValue} />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        case 'IntegerField':
        case 'DecimalField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsFieldText")}</ControlLabel>
                    <FormControl type="text" name='text' value={values.text} onChange={handleChange} />
                    <FormControl.Feedback />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsFieldTextLocation")}</ControlLabel>
                    <CustomRadio
                        key="beforeRadio"
                        name="textposition"
                        label={t("protocols.visit.formTemplate.optionsOptionsBefore")}
                        number="beforeRadio"
                        option={0}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                    />
                    <CustomRadio
                        key="afterRadio"
                        name="textposition"
                        label={t("protocols.visit.formTemplate.optionsOptionsAfter")}
                        number="afterRadio"
                        option={1}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        case 'RadioField':
        case 'MultiCheckBoxField':
        case 'SelectField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsOptions")}</ControlLabel>
                    <FormControl componentClass="textarea" name='options' value={values.options} onChange={handleChange} />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        case 'SelectAsyncField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsMasterData")}</ControlLabel>
                    <CustomSelect
                        value={values.masterData}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        name="masterData"
                        getOptionValue={(option) => option.code}
                        options={values.options ?? []} />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        case 'SnomedField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsSNOMEDExp")}</ControlLabel>
                    <FormControl type="text" name='snomedExpression' value={values.snomedExpression} onChange={handleChange} />
                    <FormControl.Feedback />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsSNOMED")}</ControlLabel>
                    <SnomedPicker
                        id={values.name}
                        selected={values.value ? [values.value] : []}
                        name="value"
                        placeholder="Seleccionar Término SNOMED"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        conceptId={values.snomedExpression ?? snomedExpressions.Procedimiento} />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        case 'ProcedureSnomedField':
            dynamicOptions = [allFieldsProps, <div key="fields_custom">
                <FormGroup>
                    <ControlLabel>{t("protocols.visit.formTemplate.optionsSNOMED")}</ControlLabel>
                    <SnomedPicker
                        id={values.name}
                        selected={values.value ? [values.value] : []}
                        name="value"
                        placeholder="Seleccionar Término SNOMED"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        conceptId={values.snomedExpression ?? snomedExpressions.Procedimiento} />
                    <FormControl.Feedback />
                </FormGroup>
            </div>];
            break;
        default:
            break;
    }

    return (
        <div className="card" style={{ height: 'auto', position: 'sticky', top: 0 }}>
            <div className="header">
                <h4 className="title">{t("protocols.visit.formTemplate.optionsTitle")}</h4>
            </div>
            <div className="content">
                <form onSubmit={handleSubmit} autoComplete="off">
                    {dynamicOptions}
                    <button type="submit" className="btn btn-success btn-right" >{t("buttons.apply")}</button>
                    <button type="button" onClick={onClose} className="btn btn-primary btn-right" >{t("buttons.close")}</button>
                    <div className="clearfix"></div>
                </form>
            </div>
        </div>
    );
};

export const DynamicOptionsFormik = withTranslation()(withFormik({

    mapPropsToValues: (props) => (props.fieldOptions),

    enableReinitialize: true,

    handleSubmit: (formData, formikBag) => {
        console.log(formData)
        formikBag.props.onSubmit(formData);
    },

    displayName: 'DynamicOptionsFormik',

})(DynamicFormOptions));

DynamicOptionsFormik.propTypes = {
    fieldOptions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};