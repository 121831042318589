import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { searchConcept, searchConceptByExpression } from '../../api/snomed';
import { HandleApiError } from "../Notifications/APIErrorHandler";

import 'react-bootstrap-typeahead/css/Typeahead.css';

export const SnomedPicker = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleChange = value => {
        if (value.length > 0) {
            if (props.multiple)
                props.onChange(props.name, value);
            else
                props.onChange(props.name, value[0]);
        }
        else
            props.onChange(props.name, null);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    const onSearch = (term) => {
        setIsLoading(true);

        let func = null;
        let query = "";

        if (props.conceptId) {
            func = searchConcept;
            query = props.conceptId;
        }
        else {
            func = searchConceptByExpression;
            query = props.expression;
        }

        func(query, encodeURIComponent(term), props.conceptType).then(response => {

            setOptions(response.data);
            setIsLoading(false);

        }).catch(error => {
            setIsLoading(false);
            setOptions([]);
            HandleApiError(error);
        });
    }

    return <AsyncTypeahead
        {...props}
        labelKey="description"
        filterBy={() => true}
        options={options}
        isLoading={isLoading}
        onSearch={onSearch}
        //clearButton={clearButton}
        highlightOnlyResult={true}
        delay={500}
        onChange={handleChange}
        onBlur={handleBlur}
    />;
}

SnomedPicker.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    minLength: PropTypes.number,
    conceptId: PropTypes.string,
    expression: PropTypes.string,
    clearButton: PropTypes.bool,
    conceptType: PropTypes.string
};

SnomedPicker.defaultProps = {
    multiple: false,
    onChange: () => { },
    minLength: 3,
    allowNew: false,
    clearButton: true
};